export const USER = 'USER';
export const GLOBALMODERATOR = 'GLOBALMODERATOR';
export const MODERATOR = 'MODERATOR';
export const GLOBALSUPERADMIN = 'GLOBALSUPERADMIN';
export const GLOBALADMIN = 'GLOBALADMIN';
export const CUSTOM_ROLE = 'CUSTOM_ROLE';
export const GLOBALBOT = 'GLOBALBOT';
export const CHANNELSUPERADMIN = 'CHANNELSUPERADMIN';
export const CHANNELMODERATOR = 'SUPERADMIN';
export const SERVICEPROVIDER = 'SERVICEPROVIDER';
export const SERVICEOPERATOR = 'SERVICEOPERATOR';
export const GLOBALBOTOPERATOR = 'GLOBALBOTOPERATOR';
export const AREABOIBOT = 'AREABOIBOT';
export const CHANNELBOT = 'CHANNELBOT';
