import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Card, Button, CardHeader, Row, Col } from "shards-react";
import axios from "axios";
import { firestore } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { database } from "../../firebase";
import { onValue, ref, off } from "firebase/database";
import useDatabase from "../../hooks/useDatabase";
import StreamSidebar from "./StreamSidebar";
import { AuthContext } from "../../context/Context";

const StreamCard = ({ title: propTitle }) => {
  // states
  const [stream, setStream] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [channels, setChannels] = useState([]);
  const [checkedRooms, setCheckedRooms] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [streamTitle, setStreamTitle] = useState("");
  const [selectedTags, setSelectedTags] = useState([]); // Ensure selectedTags is initialized as an empty array

  const { user } = useContext(AuthContext);
  const db = useDatabase();
  const userName = user ? user.profile.userName : "";
  // Handle tag selection
  const handleTagChange = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag)
        ? prevTags.filter((t) => t !== tag)
        : [...prevTags, tag]
    );
  };

  // Create stream key
  const createStream = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post(
        "http://localhost:3006/create-stream",
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      await setDoc(doc(firestore, "streams", "current"), res.data);
      setStream({ ...res.data, status: "active" });
    } catch (e) {
      console.error("Failed to create live stream:", e);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle room selection and tags
  const handleCheck = ({ key, title, tags }) => {
    setCheckedRooms(
      checkedRooms.some((room) => room.key === key)
        ? checkedRooms.filter((c) => c.key !== key)
        : [...checkedRooms, { key, title, tags }]
    );
  };

  useEffect(() => {
    // load all channels
    const loadChannels = () => {
      const refRooms = ref(database, "rooms");
      onValue(refRooms, (snapshot) => {
        const channelsObj = snapshot.val();
        if (channelsObj) {
          const channelsList = Object.keys(channelsObj).map((key) => ({
            ...channelsObj[key],
            key,
          }));
          setChannels(channelsList);
        }
      });
      return () => off(refRooms);
    };

    loadChannels();
  }, []);

  // save everything
  const handleSave = async () => {
    
    if ( checkedRooms.length < 1) {
      return;
    }
    let writes = 0;
    checkedRooms.forEach(({ key }) => {
      writes++;
      db.saveDocument(
        key,
        { title, stream, content, userName, tags: selectedTags },
        checkedRooms.length,
        writes,
        () => {
          setTitle('');
          setContent('');
           
          setCheckedRooms([]);
           
          setSelectedTags([]); // Clear tags after saving
           
          

        }
      );
    });
  };
  
  

  return (
    <Row className="stream-card-container">
      <Col lg="9" md="12">
        <Card small className="mb-3">
          <CardHeader className="border-bottom">
            <h4 className="m-0 text-primary">{propTitle}</h4>
          </CardHeader>
          <div className="p-4">
            <h5 className="font-weight-bold mb-2">Streaming Keys</h5>
            <p className="text-muted">
              Please connect with a live streaming software using the code
              provided!
            </p>
            <section className="mb-3">
              <h5 className="font-weight-bold mb-2">Server:</h5>
              <p className="text-muted">rtmps://global-live.mux.com:443/app</p>
              <h5 className="font-weight-bold mb-2">Stream Key:</h5>
              <p className="text-muted">{stream.streamKey || ""}</p>
            </section>
            <Button
              squared
              onClick={createStream}
              disabled={isLoading}
              className="btn-block mb-3"
            >
              {isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Start Stream"
              )}
            </Button>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter stream title"
              className="form-control mb-3"
            />
            <Button
              squared
              onClick={handleSave}
              className="btn-block btn-secondary"
            >
              Send Stream Data
            </Button>
          </div>
        </Card>
      </Col>
      <Col lg="3" md="12">
        <StreamSidebar
          channels={channels}
          handleCheck={handleCheck}
          checkedRooms={checkedRooms}
          handleTagChange={handleTagChange}
          selectedTags={selectedTags} // Pass the selected tags state
        />
      </Col>
    </Row>
  );
};

StreamCard.propTypes = {
  title: PropTypes.string,
};

StreamCard.defaultProps = {
  title: "Live Stream",
};

export default StreamCard;
