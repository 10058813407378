import {
  child,
  equalTo,
  off,
  onChildAdded,
  orderByKey,
  push,
  query,
  ref as refD,
  setPriority,
  setWithPriority,
  update
} from 'firebase/database';
import { database } from '../firebase';

const useDatabase = () => {
  const saveDocument = (
    roomKey,
    { title, content, movieCID, userName, tags, imgLink, stream },
    roomLength,
    writes,
    callback
  ) => {
    const user = `Areaboi ${userName}`;
    console.log('Save Document', roomKey, title, content, movieCID);
    const chatsRef = child(refD(database, 'chat'), roomKey); 

    const newPublish = push(chatsRef);
    const msgKey = newPublish.key;

    // Set default value for 'stream' if it's undefined
    const sanitizedStream = stream !== undefined ? stream : null;

    const data =
      roomKey === 'Cinema'
        ? {
            cache_version: 28,
            name: user,
            tit: title,
            title,
            video: movieCID,
            room_key: roomKey,
            tags,
            stream: sanitizedStream, // Use sanitized stream value
          }
        : {
            cache_version: 28,
            name: user,
            tit: title,
            embedHTML: content,
            room_key: roomKey,
            tags,
            imgLink:  imgLink || "",
            stream: sanitizedStream, // Use sanitized stream value
          };

    const t = Date.now();
    setWithPriority(newPublish, data, t);
    setPriority(chatsRef, t);
    update(child(chatsRef, msgKey), data);

    const orderedChatsRef = query(chatsRef, orderByKey(), equalTo(msgKey));
    onChildAdded(
      orderedChatsRef,
      (snapshot) => {
        off(orderedChatsRef);
        let dataSnap = snapshot.val();
        dataSnap.time = snapshot.exportVal()['.priority'] || snapshot['.priority'];
        update(child(chatsRef, msgKey), dataSnap);
      },
      { onlyOnce: true }
    );

    if (roomLength === writes) {
      setTimeout(() => {
        callback();
      }, 2000);
    }
  };

  return { saveDocument };
};

export default useDatabase;
