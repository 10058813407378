import React, { useState, useContext } from 'react';
import ReactQuill from 'react-quill';
import {
  Card,
  CardBody,
  Form,
  FormInput,
  ListGroupItem,
  Button
} from 'shards-react';
import Modal from '../common/Modal';
import useDatabase from '../../hooks/useDatabase';
import 'react-quill/dist/quill.snow.css';
import '../../assets/quill.css';
import { AuthContext } from '../../context/Context';
 
 

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ['clean' ]
  ]
};

const formats = [
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'align',
  'color',
  'background'
];

const Editor = ({
  checkedRooms,
  setCheckedRooms,
  setCheckedPublicChannel,
  checkedPublicChannel,
  handleTagChange,
   
  selectedTags,
  setSelectedTags,
 
}) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [contentLength, setContentLength] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenPublic, setModalOpenPublic] = useState(false);
  const [imgLink, setImgLink] = useState('');
  const [img, setImg] = useState('');
  const [file, setFile] = useState(null);
  console.log("file",file)
 console.log("fileUrl",img)
   
console.log("checkedRooms",checkedRooms )
console.log("selectedTags", selectedTags)
  const db = useDatabase();
  const { user } = useContext(AuthContext);
  const userName = user ? user.profile.userName : '';

  const titleChange = ({ target: { value } }) => {
    setTitle(value);
  };

  const imgLinkChange = ({ target: { value } }) => {
    setImgLink(value);
  };

 
  const editorChange = (content, delta, source, editor) => {
    setContent(content);
    setContentLength(editor.getLength());
  };

  const handleFirstFormSubmit = () => {
    if (!watch("category")) {
        setError("category", {message: "Select a Category"})
        return;
    }
    if (mediaUrls.length === 0) {
        setUploadStatus("Please upload a photo");
        return;
    }
    setShowForm2(true);
}   
  // const handleTagChange = (tag) => {
  //   setSelectedTags((prevTags) =>
  //     prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
  //   );
  // };

  const handleSave = () => {
    // console.log(object)
    if (contentLength <= 1 || checkedRooms.length < 1) {
      return;
    }
  
    let writes = 0;
    checkedRooms.forEach(({ key }) => {
      writes++;
      db.saveDocument(
        key,
        { title, content, userName, imgLink, tags: selectedTags,img, },
        checkedRooms.length,
        writes,
        () => {
          setTitle('');
          setContent('');
          setModalOpen(false);
          setCheckedRooms([]);
          setImgLink('');
          setSelectedTags([]); // Clear tags after saving
          setImg("")
          

        }
      );
    });
  };
  
  const handlePublicSave = () => {
    if (contentLength <= 1 || checkedPublicChannel.length < 1) {
      return;
    }
  
    let writes = 0;
    checkedPublicChannel.forEach(({ channelId }) => {
      writes++;
      db.saveDocument(
        channelId,
        { title, content, userName, imgLink, tags: selectedTags },
        checkedPublicChannel.length,
        writes,
        () => {
          setTitle('');
          setContent('');
          setModalOpenPublic(false);
          setCheckedPublicChannel([]);
          setImgLink('');
          setSelectedTags([]); // Clear tags after saving
        }
      );
    });
  };
  

   

  const selectedPublicChannel = () => (
    <ul className="list-unstyled list-group overflow-auto">
      {checkedPublicChannel &&
        checkedPublicChannel.map(({ channelName }, index) => (
          <li key={index} className="list-group-item">
            {channelName}
          </li>
        ))}
    </ul>
  );

  const selectedRooms = () => (
    <ul className="list-unstyled list-group overflow-auto">
      {checkedRooms &&
        checkedRooms.map(({ title, tags }, index) => (
          <li key={index} className="list-group-item">
            {title}
            <div>
              {tags && tags.length ? (
                tags.map((tag) => (
                  <div key={tag}>
                    <input
                      type="checkbox"
                      checked={selectedTags.includes(tag)}
                      onChange={() => handleTagChange(tag)}
                    />{' '}
                    <span>{tag}</span>
                  </div>
                ))
              ) : null}
            </div>
          </li>
        ))}
    </ul>
  );
  
  return (
    <>
      {modalOpen && (
        <Modal
          modalOpen={modalOpen}
          modalTitle="Publish to these Channel(s)"
          children={selectedRooms()}
          affirmText="Publish"
          action={handleSave}
          closeModal={() => setModalOpen(false)}
        />
      )}

      {modalOpenPublic && (
        <Modal
          modalOpen={modalOpenPublic}
          modalTitle="Publish to these Channel(s)"
          children={selectedPublicChannel()}
          affirmText="Publish"
          action={handlePublicSave}
          closeModal={() => setModalOpenPublic(false)}
        />
      )}

      <Card small className="mb-3">
        <CardBody>
          <Form className="add-new-post">
            <FormInput
              size="lg"
              className="mb-3"
              placeholder="Your Post Title"
              value={title}
              onChange={titleChange}
            />
          
                
                
                 
           
           
            <ReactQuill
              className="add-new-post__editor mb-1"
              modules={modules}
              formats={formats}
              value={content}
              onChange={editorChange}
            />
          </Form>
        </CardBody>

        <ListGroupItem className="d-flex px-3 border-0">
          <Button
            onClick={() => setModalOpen(true)}
            theme="accent"
            size="sm"
            className="ml-auto"
          >
            <i className="material-icons">file_copy</i> Publish
          </Button>
          <Button
            onClick={() => setModalOpenPublic(true)}
            theme="accent"
            size="sm"
            className="ml-auto"
          >
            <i className="material-icons">file_copy</i> Publish to Public Channel
          </Button>
        </ListGroupItem>
      </Card>
    </>
  );
};

export default Editor;
