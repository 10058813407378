import React, { useState, useEffect } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormInput,
  ListGroupItem,
  Button,
} from "shards-react";

import { database } from "../../firebase";
import {
  query,
  ref as refD,
  get,
  set,
  update,
  remove,
} from "firebase/database";
import { formatMessageTimestamp } from "../../helpers/formatTimestamp";
import {
  handleMuteOrUnmute,
  handleBanOrUnBan,
  handlePenalizeOrUnPenalize,
  getActionBannedText,
  getActionMuteText,
  getActionPenalizedText,
  getActionShadowBannedText,
  handleShadowBanOrUnShadowBan,
  handleUpRankOrUnUpRank,
  getActionUprankText,
} from "../../utils/userPostsServices";

const ACTION_TYPE = {
  HIDE: "hide",
  UNHIDE: "unhide",
  PENALIZE: "penalize",
  UNPENALIZE: "unpenalize",
  UPRANK: "uprank",
  DOWNRANK: "downrank",
  TAKEDOWN: "takedown",
  UNTAKEDOWN: "untakedown",
  REPLYUSER: "replyuser",
  UNREPLYUSER: "unreplyuser",
};
function UserPostMessages({ channelId, channelName }) {
  const [replyText, setReplyText] = useState("");
  const [isReplyTextfieldShown, setIsReplyTextfieldShown] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);
  const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false);
  const [isHidingMessage, setIsHidingMessage] = useState(false);
  const [isHidingMessages, setIsHidingMessages] = useState(false);
  const [isUnHidingMessages, setIsUnHidingMessages] = useState(false);
  const [isPenalizingMessage, setIsPenalizingMessage] = useState(false);
  const [isPenalizingMessages, setIsPenalizingMessages] = useState(false);
  const [isUnPenalizingMessages, setIsUnPenalizingMessages] = useState(false);
  const [isUpRanking, setIsUnRanking] = useState(false);
  const [isUpRankingMessages, setIsUpRankingMessages] = useState(false);
  const [isDownRankingMessages, setIsDownRankingMessages] = useState(false);
  const [isTakingDownMessages, setIsTakingDownMessages] = useState(false);
  const [isUnTakingDownMessages, setIsUnTakingDownMessages] = useState(false);
  const [isReplyingUserMessages, setIsReplyingUserMessages] = useState(false);
  const [isUnReplyingUserMessages, setIsUnReplyingUserMessages] =
    useState(false);
  const [isUndoingAction, setIsUndoingAction] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);

  const handleFetchChannelMessages = async () => {
    try {
      if (isFetchingMessages || !channelId) return;
      setIsFetchingMessages(true);

      const messagesQuery = query(refD(database, `messages/${channelId}`));

      const snapshot = await get(messagesQuery);
      if (snapshot.exists()) {
        const messagesObj = snapshot.val();
        const messagesArr = Object.keys(messagesObj).map((key) => ({
          ...messagesObj[key],
          isActionDropdownOpen: false,
          messageId: key,
        }));
        setMessages(messagesArr);
        console.log(messagesArr);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingMessages(false);
    }
  };

  const addOrRemoveSelectedMessages = (messageId) => {
    setSelectedMessages((prevSelectedMessage) => {
      if (prevSelectedMessage.includes(messageId)) {
        // Remove the username if it's already in the list
        return prevSelectedMessage.filter(
          (prevMessageId) => prevMessageId !== messageId
        );
      } else {
        // Add the username if it's not in the list
        return [...prevSelectedMessage, messageId];
      }
    });
  };

  const toggleActionDropdown = (index) => {
    setMessages(
      messages.map((message, i) =>
        i === index
          ? { ...message, isActionDropdownOpen: !message.isActionDropdownOpen }
          : message
      )
    );
  };

  const handleSetIsHidingMessage = (bool) => {
    setIsHidingMessage(bool);
  };
  const handleSetIsPenalizingMessage = (bool) => {
    setIsPenalizingMessage(bool);
  };
  const handleSetIsUpRankingMessage = (bool) => {
    setIsUpRankingMessage(bool);
  };
  const handleSetIsUndoingMessage = (bool) => {
    setIsUndoingMessage(bool);
  };

  // const handleMuteUser = (userId) => {
  //   const userStatusRef = refD(
  //     database,
  //     `publicChannelModeration/${channelId}/${userId}`
  //   );
  //   update(userStatusRef, { isMuted: true })
  //     .then(() => {
  //       console.log(`User ${userId} has been muted in channel ${channelId}`);
  //     })
  //     .catch((error) => {
  //       console.error("Error muting user:", error);
  //     });
  // };

  // const handleBanUser = (userId) => {
  //   const userStatusRef = refD(
  //     database,
  //     `publicChannelModeration/${channelId}/${userId}`
  //   );
  //   update(userStatusRef, { isBaned: true })
  //     .then(() => {
  //       console.log(`User ${userId} has been banned from channel ${channelId}`);
  //     })
  //     .catch((error) => {
  //       console.error("Error banning user:", error);
  //     });
  // };

  // const handleShadowBanUser = (userId) => {
  //   const userStatusRef = refD(
  //     database,
  //     `publicChannelModeration/${channelId}/${userId}`
  //   );
  //   update(userStatusRef, { isShadowBaned: true })
  //     .then(() => {
  //       console.log(`User ${userId} has been muted in channel ${channelId}`);
  //     })
  //     .catch((error) => {
  //       console.error("Error muting user:", error);
  //     });
  // };

  const handlePenalizeMessage = async (actionType, messageId) => {
    try {
      const userStatusRef = refD(
        database,
        `messages/${channelId}/${messageId}`
      );

      const snapshot = await get(userStatusRef);
      if (!snapshot.exists()) {
        console.error(
          `Message ${messageId} does not exist in channel ${channelId}`
        );
        return;
      }

      let isPenalized = snapshot.val().isPenalized;
      if (isPenalized === undefined || isPenalized === null) {
        isPenalized = 0; // Default to 0 if the property doesn't exist
      }

      if (actionType === ACTION_TYPE.PENALIZE && isPenalized < 3) {
        isPenalized += 1;
      } else if (actionType === ACTION_TYPE.UNPENALIZE && isPenalized > 0) {
        isPenalized -= 1;
      } else {
        return;
      }

      await update(userStatusRef, { isPenalized });
      console.log(
        `Message ${messageId} has been ${actionType}d in channel ${channelId}`
      );
    } catch (error) {
      console.error("Error updating penalize status:", error);
    } finally {
      setSelectedMessages([]);
      handleFetchChannelMessages();
    }
  };

  const handleHideMessage = (actionType, messageId) => {
    let action;
    if (actionType === ACTION_TYPE.HIDE) {
      action = true;
    } else if (actionType === ACTION_TYPE.UNHIDE) {
      action = false;
    } else {
      return;
    }
    const userStatusRef = refD(database, `messages/${channelId}/${messageId}`);
    console.log(action);
    update(userStatusRef, { isHidden: action })
      .then(() => {
        console.log(
          `Message ${messageId} has been ${actionType} from channel ${channelId}`
        );
      })
      .catch((error) => {
        console.error("Error hiding message:", error);
      })
      .finally(() => {
        setSelectedMessages([]);
        handleFetchChannelMessages();
      });
  };
  const handleUpRankMessage = async (actionType, messageId) => {
    try {
      const userStatusRef = refD(
        database,
        `messages/${channelId}/${messageId}`
      );

      const snapshot = await get(userStatusRef);
      if (!snapshot.exists()) {
        console.error(
          `Message ${messageId} does not exist in channel ${channelId}`
        );
        return;
      }

      let isUpRanked = snapshot.val().isUpRanked;
      if (isUpRanked === undefined || isUpRanked === null) {
        isUpRanked = 0; // Default to 0 if the property doesn't exist
      }

      if (actionType === ACTION_TYPE.UPRANK && isUpRanked < 3) {
        isUpRanked += 1;
      } else if (actionType === ACTION_TYPE.UNUPRANK && isUpRanked > 0) {
        isUpRanked -= 1;
      } else {
        return;
      }

      await update(userStatusRef, { isUpRanked });
      console.log(
        `Message ${messageId} has been ${actionType}d in channel ${channelId}`
      );
    } catch (error) {
      console.error("Error updating penalize status:", error);
    } finally {
      setSelectedMessages([]);
      handleFetchChannelMessages();
    }
  };
  const handleTakeDownMessage = (actionType, messageId) => {
    let action;
    const userStatusRef = refD(database, `messages/${channelId}/${messageId}`);

    if (actionType === ACTION_TYPE.TAKEDOWN) {
      remove(userStatusRef)
        .then(() => {
          console.log(
            `Message ${messageId} has been ${actionType} in channel ${channelId}`
          );
        })
        .catch((error) => {
          console.error("Error taking down message:", error);
        })
        .finally(() => {
          setSelectedMessages([]);
          handleFetchChannelMessages();
        });
    } else if (actionType === ACTION_TYPE.UNTAKEDOWN) {
      action = false;
    } else {
      return;
    }
  };
  const handleReplyUser = (actionType, messageId) => {
    let action;
    if (actionType === ACTION_TYPE.REPLYUSER) {
      action = true;
    } else if (actionType === ACTION_TYPE.UNREPLYUSER) {
      action = false;
    } else {
      return;
    }
    const userStatusRef = refD(database, `msgComments/${messageId}`);
    const commentData = {
      messageContent: replyText,
      messageType: "text",
      sentAt: Date.now(),
      sentBy: "Admin",
    };
    set(userStatusRef, commentData)
      .then(() => {
        console.log(
          `Message ${messageId} has been ${actionType} in channel ${channelId}`
        );
      })
      .catch((error) => {
        console.error("Error hiding message:", error);
      })
      .finally(() => {
        setSelectedMessages([]);
        setReplyText("");
        handleFetchChannelMessages();
      });
  };
  const actionUndo = (messageId) => {
    const userStatusRef = refD(database, `messages/${channelId}/${messageId}`);
    update(userStatusRef, { isUpRanked: false, isPenalized: false })
      .then(() => {
        console.log(
          `Message ${messageId} has undone actions in channel ${channelId}`
        );
      })
      .catch((error) => {
        console.error("Error hiding message:", error);
      })
      .finally(() => {
        setSelectedMessages([]);
        handleFetchChannelMessages();
      });
  };

  const handleHideUnhideMessages = (actionType) => {
    if (!selectedMessages.length) return;

    if (actionType === ACTION_TYPE.HIDE) {
      setIsHidingMessages(true);
    } else if (actionType === ACTION_TYPE.UNHIDE) {
      setIsUnHidingMessages(true);
    } else return;

    selectedMessages.forEach((messageId) => {
      handleHideMessage(actionType, messageId);
    });

    if (actionType === ACTION_TYPE.HIDE) {
      setIsHidingMessages(false);
    } else if (actionType === ACTION_TYPE.UNHIDE) {
      setIsUnHidingMessages(false);
    }
  };

  const handlePenalizeUnPenalizeMessages = (actionType) => {
    if (!selectedMessages.length) return;
    if (actionType === ACTION_TYPE.PENALIZE) {
      setIsPenalizingMessages(true);
    } else if (actionType === ACTION_TYPE.UNPENALIZE) {
      setIsUnPenalizingMessages(true);
    } else return;

    selectedMessages.forEach((messageId) => {
      handlePenalizeMessage(actionType, messageId);
    });

    if (actionType === ACTION_TYPE.PENALIZE) {
      setIsPenalizingMessages(false);
    } else if (actionType === ACTION_TYPE.UNPENALIZE) {
      setIsUnPenalizingMessages(false);
    }
  };
  const handleUpRankDownRankMessages = (actionType) => {
    if (!selectedMessages.length) return;
    if (actionType === ACTION_TYPE.UPRANK) {
      setIsUpRankingMessages(true);
    } else if (actionType === ACTION_TYPE.DOWNRANK) {
      setIsDownRankingMessages(true);
    } else return;

    selectedMessages.forEach((messageId) => {
      handleUpRankMessage(actionType, messageId);
    });

    if (actionType === ACTION_TYPE.UPRANK) {
      setIsUpRankingMessages(false);
    } else if (actionType === ACTION_TYPE.DOWNRANK) {
      setIsDownRankingMessages(false);
    }
  };
  const handleReplyUserUnReplyUserMessages = (actionType) => {
    if (!selectedMessages.length) return;
    if (actionType === ACTION_TYPE.REPLYUSER) {
      if (!replyText.length) return;
      setIsReplyingUserMessages(true);
    } else if (actionType === ACTION_TYPE.UNREPLYUSER) {
      setIsUnReplyingUserMessages(true);
    } else return;

    selectedMessages.forEach((messageId) => {
      handleReplyUser(actionType, messageId);
    });

    if (actionType === ACTION_TYPE.REPLYUSER) {
      setIsReplyingUserMessages(false);
    } else if (actionType === ACTION_TYPE.UNREPLYUSER) {
      setIsUnReplyingUserMessages(false);
    }
  };
  const handleTakeDownUnTakedownMessages = (actionType) => {
    if (!selectedMessages.length) return;
    if (actionType === ACTION_TYPE.TAKEDOWN) {
      setIsTakingDownMessages(true);
    } else if (actionType === ACTION_TYPE.UNTAKEDOWN) {
      setIsUnTakingDownMessages(true);
    } else return;

    selectedMessages.forEach((messageId) => {
      handleTakeDownMessage(actionType, messageId);
    });

    if (actionType === ACTION_TYPE.TAKEDOWN) {
      setIsTakingDownMessages(false);
    } else if (actionType === ACTION_TYPE.UNTAKEDOWN) {
      setIsUnTakingDownMessages(false);
    }
  };

  const handleActionUndo = (actionType) => {
    if (!selectedMessages.length) return;
    setIsUndoingAction(true);

    selectedMessages.forEach((messageId) => {
      actionUndo(messageId);
    });

    setIsUndoingAction(false);
  };

  // const handleUnMuteUser = (userId) => {
  //   const userStatusRef = refD(
  //     database,
  //     `publicChannelModeration/${channelId}/${userId}`
  //   );
  //   update(userStatusRef, { isMuted: false })
  //     .then(() => {
  //       console.log(`User ${userId} has been unmuted in channel ${channelId}`);
  //     })
  //     .catch((error) => {
  //       console.error("Error un-muting user:", error);
  //     });
  // };

  // const handleUnBanUser = (userId) => {
  //   const userStatusRef = refD(
  //     database,
  //     `publicChannelModeration/${channelId}/${userId}`
  //   );
  //   update(userStatusRef, { isBaned: false })
  //     .then(() => {
  //       console.log(`User ${userId} has been banned from channel ${channelId}`);
  //     })
  //     .catch((error) => {
  //       console.error("Error banning user:", error);
  //     });
  // };

  // const handleUnShadowBanUser = (userId) => {
  //   const userStatusRef = refD(
  //     database,
  //     `publicChannelModeration/${channelId}/${userId}`
  //   );
  //   update(userStatusRef, { isShadowBaned: false })
  //     .then(() => {
  //       console.log(
  //         `User ${userId} has been un-shadow-banned in channel ${channelId}`
  //       );
  //     })
  //     .catch((error) => {
  //       console.error("Error un-shadow-banning user:", error);
  //     });
  // };

  const handleUnPenalizeMessage = (messageId) => {
    const userStatusRef = refD(database, `messages/${channelId}/${messageId}`);
    update(userStatusRef, { isPenalized: false })
      .then(() => {
        console.log(
          `Message ${messageId} has been un-penalized from channel ${channelId}`
        );
      })
      .catch((error) => {
        console.error("Error un-penalizing user:", error);
      });
  };
  const handleUnHideMessage = (messageId) => {
    const userStatusRef = refD(
      database,
      `publicChannelModeration/${channelId}/${messageId}`
    );
    update(userStatusRef, { isHidden: false })
      .then(() => {
        console.log(
          `Message ${messageId} has been banned from channel ${channelId}`
        );
      })
      .catch((error) => {
        console.error("Error un-hiding message:", error);
      });
  };

  useEffect(() => {
    if (channelId) {
      handleFetchChannelMessages();
    }
  }, [channelId]);

  return (
    <>
      <Card small className="mb-3">
        <CardHeader>
          <h5>{channelName}</h5>
        </CardHeader>
        <CardBody>
          {messages.map((message, index) => (
            <div key={index} className="messages-container">
              <div className="messages-item">
                <div className="messages-user d-flex align-items-center gap-4">
                  <h6
                    style={{
                      fontWeight: 700,
                      fontSize: "14px",
                      marginBottom: 0,
                      marginRight: "20px",
                    }}
                  >
                    {message.sentBy}
                  </h6>
                </div>

                <div className="messages-info mt-2">
                  <div className="d-flex align-items-center">
                    <small className="mr-3">
                      {formatMessageTimestamp(message.sentAt)}
                    </small>
                  </div>
                  <div key={index} className="d-flex align-items-center mb-3">
                    <label
                      className="mb-0 mr-2 channelUsersLabel"
                      htmlFor="publicChannelMessages"
                    >
                      <input
                        type="checkbox"
                        id="publicChannelMessages"
                        name="publicChannelMessages"
                        onChange={() => {
                          addOrRemoveSelectedMessages(message.messageId);
                        }}
                        checked={selectedMessages.includes(message.messageId)}
                      />
                    </label>
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        marginBottom: "1px",
                      }}
                    >
                      {message.messageContent}
                      <span className="userPost-moderation-indicator">
                        {message.isHidden && (
                          <span style={{ fontWeight: "bold" }}>H</span>
                        )}
                        {message.isPenalized > 0 && (
                          <span style={{ fontWeight: "bold" }}>
                            {message.isPenalized == 1 && "P1"}
                            {message.isPenalized == 2 && "P2"}
                            {message.isPenalized == 3 && "P3"}
                          </span>
                        )}
                        {message.isUpRanked > 0 && (
                          <span style={{ fontWeight: "bold" }}>
                            {message.isUpRanked == 1 && "U1"}
                            {message.isUpRanked == 2 && "U2"}
                            {message.isUpRanked == 3 && "U3"}
                          </span>
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </CardBody>
      </Card>
      <div className="d-flex justify-content-between align-items-center fixed-bottom-container gap-3">
        <div className="userPost-userActions">
          <button
            onClick={() => {
              handleHideUnhideMessages(ACTION_TYPE.HIDE);
            }}
            disabled={isHidingMessages}
          >
            {isHidingMessages ? "Loading" : "Hide"}
          </button>
          <button
            onClick={() => {
              handleHideUnhideMessages(ACTION_TYPE.UNHIDE);
            }}
            disabled={isUnHidingMessages}
          >
            {isUnHidingMessages ? "Loading" : "UnHide"}
          </button>
        </div>
        <div className="userPost-userActions">
          <button
            onClick={() => {
              handlePenalizeUnPenalizeMessages(ACTION_TYPE.PENALIZE);
            }}
            disabled={isPenalizingMessages}
          >
            {isPenalizingMessages ? "Loading" : "Penalize"}
          </button>
          <button
            onClick={() => {
              handlePenalizeUnPenalizeMessages(ACTION_TYPE.UNPENALIZE);
            }}
            disabled={isUpRankingMessages}
          >
            {isUnPenalizingMessages ? "Loading" : "Unpenalize"}
          </button>
        </div>
        <div className="userPost-userActions">
          <button
            onClick={() => {
              handleUpRankDownRankMessages(ACTION_TYPE.UPRANK);
            }}
            disabled={isUpRankingMessages}
          >
            {isUpRankingMessages ? "Loading" : "Uprank"}
          </button>
          <button
            onClick={() => {
              handleUpRankDownRankMessages(ACTION_TYPE.DOWNRANK);
            }}
            disabled={isDownRankingMessages}
          >
            {isDownRankingMessages ? "Loading" : "Downrank"}
          </button>
        </div>
        <div className="userPost-userActions">
          <button
            onClick={() => {
              handleTakeDownUnTakedownMessages(ACTION_TYPE.TAKEDOWN);
            }}
            disabled={isTakingDownMessages}
          >
            {isTakingDownMessages ? "Loading" : "Take Down"}
          </button>

          <div
            style={{
              position: "relative",
              // width: "100px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsReplyTextfieldShown(!isReplyTextfieldShown);
              }}
              style={{ position: "relative" }}
            >
              {isReplyingUserMessages ? "Loading" : "Reply User"}
            </button>
            {isReplyTextfieldShown && (
              <div
                style={{
                  position: "absolute",
                  top: "-110%",
                  left: "0",
                }}
              >
                <input
                  type="text"
                  onChange={(e) => {
                    e.stopPropagation();
                    setReplyText(e.target.value);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  value={replyText}
                  placeholder="type message..."
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReplyUserUnReplyUserMessages(ACTION_TYPE.REPLYUSER);
                  }}
                  disabled={isReplyingUserMessages}
                  style={{ position: "absolute", right: "0", top: "100%" }}
                >
                  Send
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UserPostMessages;
