import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Progress
} from 'shards-react';

const UserDetails = ({ user }) => {
const avatar = user.aristo.aristoMetaData.image
const userName = user.profile.userName
const role = user.role
const repScore = user.profile.repScore


return (


  <Card small className="mb-4 pt-3">
    <CardHeader className="border-bottom text-center">
      <div className="mb-3 mx-auto">
        <img
          className="rounded-circle"
          src={avatar}
          alt="alt"
          width="110"
        />
      </div>
      <h4 className="mb-0">{userName}</h4>
      <span className="text-muted d-block mb-2">{role}</span>
  
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="px-4">
        <div className="progress-wrapper">
          <strong className="text-muted d-block mb-2">
          Rep Score
          </strong>
          <Progress
            className="progress-sm"
            value={repScore}>
            <span className="progress-value">
              {repScore}%
            </span>
          </Progress>
        </div>
      </ListGroupItem>
      {/* <ListGroupItem className="p-4">
        <strong className="text-muted d-block mb-2">
          {userDetails.metaTitle}
        </strong>
        <span>{userDetails.metaValue}</span>
      </ListGroupItem> */}
    </ListGroup>
  </Card>
)

}
 

// UserDetails.defaultProps = {
//   userDetails: {
//     name: 'Sierra Brooks',
//     avatar: require('./../../images/avatars/0.jpg'),
//     jobTitle: 'Project Manager',
//     performanceReportTitle: 'Workload',
//     performanceReportValue: 74,
//     metaTitle: 'Description',
//     metaValue:
//       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?'
//   }
// };

export default UserDetails;
