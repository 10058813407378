import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Button, CardHeader, FormInput } from "shards-react";
import { withRouter, Redirect } from "react-router-dom";

import { auth } from "../../firebase";
import { AuthContext } from "../../context/Context";
import {
  GoogleAuthProvider,
  browserSessionPersistence,
  getAdditionalUserInfo,
  signInWithPhoneNumber,
  setPersistence,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";
import { child, onValue, ref as refD } from "firebase/database";
 
import Register from "./Register";
 
const googleBtn = {
  padding: "12px 18px",
  margin: " 0 0 50px 0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "16px",
};

const googleBtnImg = {
  width: "16px",
  height: "16px",
  padding: "0",
  margin: "0 5px",
  verticalAlign: "middle",
};

const LoginPage = ({ history, title }) => {
  // const store = useStore();
  const [error, setErrors] = useState("");
  const { user, setUser, setLoggedIn, isLoggedIn, is2FA } =
    useContext(AuthContext);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [OTP, setOTP] = useState("");
  const [phoneCode, setPhoneCode] = useState("+234");

  // user = true;

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      console.log("User logged in:", user);
      // Perform any necessary actions after user login state change
    } else {
      console.log("User logged out");
      // Perform any necessary actions after user logout state change
    }
  });

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider(); //new authInit.GoogleAuthProvider();
    setPersistence(auth, browserSessionPersistence) //firebase.auth().setPersistence(authInit.Auth.Persistence.SESSION)
      .then(() => {
        signInWithPopup(auth, provider) //auth.signInWithPopup(provider)
          .then((res) => {
            const username = getAdditionalUserInfo(res).profile.name; //res.additionalUserInfo.profile.name;
            onValue(child(refD("users"), `${username}`), (snapshot) => {
              //database.ref("users").child(`${username}`).once("value", snapshot => {
              if (snapshot.exists) {
                setUser(snapshot.val());
                setTimeout(() => {
                  setLoggedIn(true);
                  history.push("/add-new-post");
                }, 0);
              }
            });
          })
          .catch((e) => setErrors(e.message));
      });
  };

 

  return (
    <>
      {/* is2FA && user */}
      { user ? (
        <Redirect to={{ pathname: "/add-new-post" }} />
      ) : (
        <Card style={{ maxWidth: "500px" }} className="mb-3 ml-auto mr-auto">
          <span>{error}</span>
          <CardHeader className="border-bottom ml-auto mr-auto">
            <img
              src={require("./../../images/avatars/areaboi.png")}
              alt="areaboi logo"
            />
          </CardHeader>

          <div className="p-3 d-flex justify-content-center">
            <Register history={history} />
          </div>
        </Card>
      )}
    </>
  );
};

LoginPage.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

LoginPage.defaultProps = {
  title: "Login",
};

export default withRouter(LoginPage);
