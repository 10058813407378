import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, ListGroup, ListGroupItem, FormCheckbox } from 'shards-react';

const StreamSidebar = ({ channels, handleCheck, checkedRooms, handleTagChange, selectedTags }) => {
  
  console.log("selectedTags", selectedTags); 
  console.log("handleTagChange", handleTagChange); 

  return (
    <>
      {/* Channels Section */}
      <Card small className="mb-3">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Channels</h6>
        </CardHeader>
        <CardBody className="p-0">
          <ListGroup flush style={{ height: '32rem' }}>
            <ListGroupItem className="px-3 pb-2 overflow-auto">
              {channels &&
                channels.map((channel, index) => (
                  <FormCheckbox
                    key={index}
                    className="mb-1"
                    checked={checkedRooms.some((room) => room.key === channel.key)}
                    onChange={() => handleCheck(channel)}
                  >
                    {channel.title}
                  </FormCheckbox>
                ))}
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>

      {/* Tags Section */}
      <Card>
        <CardBody>
          <div>
            <p>Tags</p>
            {checkedRooms &&
              checkedRooms.map(({ tags }, index) => (
                <div key={index}>
                  {tags && tags.length ? (
                    tags.map((tag) => (
                      <div key={tag}>
                        <input
                          type="checkbox"
                          checked={selectedTags.includes(tag)}
                          onChange={() => handleTagChange(tag)}
                        />{' '}
                        <span>{tag}</span>
                      </div>
                    ))
                  ) : (
                    <p>No tags found</p>
                  )}
                </div>
              ))}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

StreamSidebar.propTypes = {
  channels: PropTypes.array.isRequired,
  handleCheck: PropTypes.func.isRequired,
  checkedRooms: PropTypes.array.isRequired,
  handleTagChange: PropTypes.func.isRequired,
  selectedTags: PropTypes.array.isRequired
};

export default StreamSidebar;
