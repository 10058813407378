import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";

import { database } from "../firebase";
import { off, onValue, query, ref as refD, get } from "firebase/database";
import UserPostMessages from "../components/user-posts/UserPostMessages";
import UserPostChannels from "../components/user-posts/UserPostChannels";
import UserPostUsers from "../components/user-posts/UserPostUsers";

const UsersPosts = () => {
  const [channelId, setChannelId] = useState("");
  const [channelName, setChannelName] = useState("");
  const [messages, setMessages] = useState([]);
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);

  const handleSetChannelInfo = (id, name) => {
    setChannelId(id);
    setChannelName(name);
    console.log(name);
  };

  const handleFetchChannelMessages = async () => {
    try {
      if (isFetchingMessages) return;
      setIsFetchingMessages(true);

      const messagesQuery = query(refD(database, `messages/${channelId}`));

      const snapshot = await get(messagesQuery);
      if (snapshot.exists()) {
        const messagesObj = snapshot.val();
        const messagesArr = Object.keys(messagesObj).map((key) => ({
          ...messagesObj[key],
          isActionDropdownOpen: false,
          messageId: key,
        }));
        setMessages(messagesArr);
        console.log(messagesArr);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingMessages(false);
    }
  };

  useEffect(() => {
    if (channelId) {
      handleFetchChannelMessages();
    }
  }, [channelId]);

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Public Channel Posts"
          subtitle="Manage User Posts"
          className="text-sm-left"
        />
      </Row>

      <Row>
        {/* Manager */}
        <Col lg="4" md="12" className="position-relative">
          <UserPostUsers channelId={channelId} channelName={channelName} />
        </Col>

        <Col lg="5" md="12">
          <UserPostMessages
            channelId={channelId}
            channelName={channelName}
            // messages={messages}
          />
        </Col>

        {/* Sidebar Widgets */}
        <Col lg="3" md="12">
          <UserPostChannels
            handleSetChannelInfo={handleSetChannelInfo}
            channelId={channelId}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default UsersPosts;
