import React, { useContext, useState } from 'react';

import { Container, Row, Col } from 'shards-react';

import PageTitle from '../components/common/PageTitle';
import UserDetails from '../components/user-profile-lite/UserDetails';
import UserAccountDetails from '../components/user-profile-lite/UserAccountDetails';
import { AuthContext } from '../context/Context';

const UserProfile = () => {


    const { user, setUser, setLoggedIn, isLoggedIn, is2FA } =
    useContext(AuthContext);
    console.log("user", user)
const userProfile = user.profile
console.log("userProfile", userProfile)

    return (


  <Container fluid className="main-content-container px-4 bg-red">
    <Row noGutters className="page-header py-4">
      <PageTitle
        title="User Profile"
        subtitle="Overview"
        md="12"
        className="ml-sm-auto mr-sm-auto"
      />
    </Row>
    <Row>
      <Col lg="4">
        <UserDetails  user={user}/>
      </Col>
      <Col lg="8">
    
        <UserAccountDetails  userProfile={userProfile}/>
      </Col>
    </Row>
  </Container>
    )
  }

export default UserProfile;
