import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from 'shards-react';

const UserAccountDetails = ({ title, userProfile }) => {
  const address = userProfile.address.display_name


  return (
    <Card small className="mb-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form>
              
              <Row form>
                {/* First Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="feFirstName">Username</label>
                  <p>{userProfile.userName}</p>
                   
                </Col>
                {/* Last Name */}
                <Col md="6" className="form-group">
                <label htmlFor="feFirstName">Phone Number</label>
                  <p>{userProfile.phoneNo}</p>
                   
                </Col>
              </Row>
              <Row form>
                {/* First Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="feFirstName">Rep Score</label>
                  <p>{userProfile.repScore}</p>
                   
                </Col>
                {/* Last Name */}
                <Col md="6" className="form-group">
                <label htmlFor="feFirstName">Address</label>
                  <p>{address}</p>
                   
                </Col>
              </Row>
        
          
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  </Card>

  )



}

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: 'Account Details'
};

export default UserAccountDetails;
