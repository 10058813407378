import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'shards-react';

import PageTitle from '../components/common/PageTitle';
import Editor from '../components/add-new-post/Editor';
import SidebarCategories from '../components/add-new-post/SidebarCategories';

import { database } from '../firebase';
import { onValue, ref, off, get } from 'firebase/database';

const AddNewPost = () => {
  const [channels, setChannels] = useState([]);
  const [checkedRooms, setCheckedRooms] = useState([]);
  const [checkedPublicChannel, setCheckedPublicChannel] = useState([]);
  const [publicChannels, setPublicChannels] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]); // New state for tags


  const refRooms = ref(database, 'rooms');

  const handleCheck = ({ key, title, tags }) => {
    setCheckedRooms(
      checkedRooms.some((room) => room.key === key)
        ? checkedRooms.filter((c) => c.key !== key)
        : [...checkedRooms, { key, title, tags }]
    );
    setTags(tags);
  };

  const handleCheckPublic = ({ channelId, channelName }) => {
    setCheckedPublicChannel(
      checkedPublicChannel.some((publicChannel) => publicChannel.channelId === channelId)
        ? checkedPublicChannel.filter((c) => c.channelId !== channelId)
        : [...checkedPublicChannel, { channelId, channelName }]
    );
  };

  // const handleTagChange = (tag) => {
  //   setSelectedTags(
  //     selectedTags.includes(tag)
  //       ? selectedTags.filter((t) => t !== tag)
  //       : [...selectedTags, tag]
  //   );
  // };

  const loadChannels = () => {
    onValue(
      refRooms,
      (snapshot) => {
        const channelsObj = snapshot.val();
        if (channelsObj) {
          const channelsList = Object.keys(channelsObj).map((key) => ({
            ...channelsObj[key],
          }));
          setChannels(channelsList);
        }
      }
    );
  };

  const loadPublicChannels = async () => {
    const channelsRef = ref(database, 'channels');
    try {
      const snapshot = await get(channelsRef);
      if (snapshot.exists()) {
        const publicChannelsData = snapshot.val();
        const publicChannelsList = Object.keys(publicChannelsData)
          .map((key) => ({
            ...publicChannelsData[key],
            channelId: key
          }))
          .filter(channel => channel.isPublic === true);
        setPublicChannels(publicChannelsList);
      } else {
        console.log("No public channels found.");
      }
    } catch (error) {
      console.error("Error retrieving all public channels:", error);
    }
  };

  useEffect(() => {
    loadChannels();
    loadPublicChannels();
    return () => off(refRooms);
  }, []);


  const handleTagChange = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
    );
  };
  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Add New Post"
          subtitle="Blog Posts"
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="9" md="12">
          <Editor
            checkedRooms={checkedRooms}
            setCheckedRooms={setCheckedRooms}
            checkedPublicChannel={checkedPublicChannel}
            setCheckedPublicChannel={setCheckedPublicChannel}
            handleCheck={handleCheck}
            channels={channels}
            tags={tags}
            handleTagChange={handleTagChange}
            setTags={setTags}  // Pass the tag change handler
            selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          
          />
        </Col>

        <Col lg="3" md="12">
          <SidebarCategories
            channels={channels}
            publicChannels={publicChannels}
            handleCheck={handleCheck}
            handleCheckPublic={handleCheckPublic}
            checkedRooms={checkedRooms}
            checkedPublicChannel={checkedPublicChannel}
            tags={tags}
            selectedTags={selectedTags}
            handleTagChange={handleTagChange}
             
            setTags={setTags}  // Pass the tag change handler
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AddNewPost;
