import React, { useState, useEffect } from "react";
import { database, auth } from "../firebase";
import useStateCallback from "../helpers/useStateCallback";
import { onAuthStateChanged } from "firebase/auth";
import { child, onValue, ref as refD } from "firebase/database";

const FirebaseContext = React.createContext(null);
export const AuthContext = React.createContext(null);
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [is2FA, setIs2FA] = useState(false);
  const [loadingAuthState, setLoadingAuthState] = useState(true);

  console.log("user", user);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        const username = authUser.displayName;
        console.log(username);
        onValue(
          child(refD(database, "users"), username),
          (snapshot) => {
            if (snapshot.exists()) {
              const userData = snapshot.val();
              setUser(userData);
              setLoggedIn(true);
              const localAuth = JSON.parse(localStorage.getItem("auth"));
              const exp = new Date().getTime();
              setIs2FA(userData && localAuth && localAuth.exp > exp);
            }
          },
          { onlyOnce: true }
        );
      } else {
        setUser(null);
        setLoggedIn(false);
        setIs2FA(false);
      }
      setLoadingAuthState(false);
    });
    console.log("unsubscribe", unsubscribe);
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        user,
        setUser,
        setLoggedIn,
        is2FA,
        setIs2FA,
        loadingAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
