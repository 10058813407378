import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button,
  InputGroup,
  InputGroupAddon,
  FormCheckbox,
  FormInput
} from 'shards-react';

const SidebarCategories = ({ title, channels, publicChannels, handleCheck, handleCheckPublic,checkedPublicChannel, checkedRooms, handleTagChange, selectedTags }) => (
 <>
  
  <Card small className="mb-3">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>
    <CardBody className="p-0">
      <ListGroup flush style={{ height: '32rem' }}>
        <ListGroupItem className="px-3 pb-2 overflow-auto">

          
          {channels &&
            channels.map((channel, index) => (
              <FormCheckbox
                key={index}
                className="mb-1"
                checked={checkedRooms.some((room) => room.key === channel.key)}
                name={channel.title}
                onChange={() => handleCheck(channel)}>
                {channel.title}
              </FormCheckbox>
            ))}

      <div>Public Channels</div>
       
{
  publicChannels && publicChannels.map((publicChannel, index) => (
    <FormCheckbox
      key={index}
      className="mb-1"
      checked={checkedPublicChannel.some((room) => room.channelId === publicChannel.channelId)} // corrected variable name and comparison
      name={publicChannel.channelName}
      onChange={() => handleCheckPublic(publicChannel)}
    >
      {publicChannel.channelName}
    </FormCheckbox>
  ))
}


      
        </ListGroupItem>


        <ListGroupItem className="d-flex px-3">
          <InputGroup className="ml-auto">
            <FormInput placeholder="New channel" />
            <InputGroupAddon type="append">
              <Button theme="white" className="px-2">
                <i className="material-icons">add</i>
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </ListGroupItem>
      </ListGroup>


      
    </CardBody>

    
  </Card>

  <Card>
    <CardBody>
    <div>
      <p>Tags</p>
              {checkedRooms &&
                checkedRooms.map(({ tags }, index) => (
                  <div key={index}>
                    {tags && tags.length ? (
                      tags.map((tag) => (
                        <div key={tag}>
                          <input
                            type="checkbox"
                            checked={selectedTags.includes(tag)}
                            onChange={() => handleTagChange(tag)}
                          />{' '}
                          <span>{tag}</span>
                        </div>
                      ))
                    ) : (
                      <p>No tags found</p>
                    )  }
                  </div>
                ))}
            </div>
    </CardBody>
  </Card>
  </>
  
);

SidebarCategories.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

SidebarCategories.defaultProps = {
  title: 'Channels'
};

export default SidebarCategories;
