import moment from "moment";
const formatMessageTimestamp = (timestamp) => {
  const messageDate = moment(timestamp);
  const now = moment();

  const diffInMinutes = now.diff(messageDate, "minutes");

  if (diffInMinutes < 1) {
    return "just now";
  } else if (messageDate.isSame(now, "day")) {
    return `today ${messageDate.format("h:mma")}`;
  } else {
    return messageDate.format("DD/MM/YYYY h:mma");
  }
};

export { formatMessageTimestamp };
